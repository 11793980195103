@import "../../../../../../utils/styles/themes/index";

.my-tasks {
  &__project {
    margin-bottom: 8px;
    background-color: #fff;
    &-header {
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      background-color: #fafafa;
      @include if-sm {
        height: 48px;
        padding: 0 8px; }
      .brill-mask {
        font-size: 40px;
        margin-left: 8px;
        margin-right: 11px;
        @include if-sm {
          display: none; } } }
    &-toggle-button {
      .icon-expand-arrow-dn {
        display: block;
        font-size: 6px;
        &:before {
          color: #16191f; } }
      &--open {
        .icon-expand-arrow-dn {
          transform: rotateX(180deg); } } }
    &-title {
      margin: 0 auto 0 0;
      font-size: 20px;
      font-weight: 700;
      color: $colorLead;
      @include if-sm {
        font-size: 16px; } }
    &-body {
      display: none;
      &--open {
        display: block; } } } }
