.HrCandidateReview {
  .HrField {
    height: 70px;
    width: 100%;
    margin: 10px 0;
    &.height-auto {
      height: auto;
    }

    &_files {
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      li {
        padding: 4px 5px;
        &:before {
          content: '•';
          margin-right: 5px;
        }
      }
    }

    &_btn {
      text-decoration: none;
      color: inherit;
    }

    &_label {
      font-size: 12px;
      color: #424A5F;
      margin: 0;
    }

    &_val {
      height: 18px;
      padding: 10px 0 3px;
      margin: 0 0 16px;
      border-bottom: 1px solid #879196;
      .ellipsis {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: none;
      }
    }

    &_conclusion {
      padding: 10px 0 3px;
      margin: 0 0 16px;
      border-bottom: 1px solid #879196;
    }
  }
}
