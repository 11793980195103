@import './themes/index';

.title--xxl {
  font-size: 22px; }
.title--xl {
  font-size: 20px; }
.title--l {
  font-size: 18px; }
.title--m {
  font-size: 16px; }
.title--s {
  font-size: 15px; }
.title--xs {
  font-size: 14px; }
.content-body {
  font-size: 14px;
  color: $colorText;
  line-height: 24px;
  h1 {
    font-size: 22px; }
  h2 {
    font-size: 20px; }
  h3 {
    font-size: 18px; }
  h4 {
    font-size: 16px; }
  h5 {
    font-size: 15px; }
  h6 {
    font-size: 14px; }
  a {
    color: #29b6f6;
    transition-duration: .25s;
    &:hover {
      color: #0288d1;
      text-decoration: underline; }
    &:visited {
        color: #9c27b0; } }
  h1, h2, h3, h4, h5, h6 {
    color: #16191f; } }
.link {
  color: #29b6f6;
  transition-duration: .25s;
  &:hover {
    color: #0288d1;
    text-decoration: underline; }
  &:visited {
    color: #9c27b0; } }
.text {
  font-size: 14px;
  color: #16191f; }
.status {
  &--normal {
    color: #16191f; }
  &--in-progress {
    color: #f79f25; }
  &--done {
    color: #4caf50; }
  &--error {
    color: #f44336; } }
.table {
  &-text {
    &--head {
      font-size: 14px;
      font-weight: 500;
      color: $colorTableHead; }
    &--new {
      font-size: 14px;
      font-weight: 500;
      color: $colorTableRow--new; }
    &--regular {
      font-size: 14px;
      font-weight: 400;
      color: $colorTableRow--regular; }
    &--closed {
      font-size: 14px;
      font-weight: 400;
      color: $colorTableRow--closed; } } }
.field__error-text {
  font-size: 11px;
  font-weight: 400;
  color: $colorRed;
  font-style: italic; }
.p-xs[class] {
  padding: 2px; }
.selectable-text {
  -webkit-user-select: auto !important;
  -moz-user-select: inherit  !important;
  -ms-user-select: inherit  !important;
  user-select: auto  !important; }
